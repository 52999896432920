import LocalizedStrings from 'react-localization';
let strings = new LocalizedStrings({
    en:{
        add_admin:"Add Admin",
        edit_admin:"Edit Admin",
        search_admin:"Search Admin",
        clear_value:"Clear value",
        add_merchant: "Add Merchant",
        search_merchant: "Search Merchant",
        clear_value:"Clear Value",
        generate_password: "Reset Password",
        delete_merchant: "Delete Merchant",
        delete: "Delete",
        account: "Account",
        search: "Search",
        go: "Go",
        download: "Download",
        close: "Close",
        clear: "Clear",
        record_tx: "Record Transaction",
        save: "Save",
        add_payment: "Add Payment",
        add_beneficiary: "Add Beneficiary",
        remove_beneficiary: "Remove Beneficiary",
        add_phone: "Add Phone",
        remove_phone: "Remove Phone",
        submit: "Submit",
        details: "Details",
        add_payin: "Make Pay In",
        resolve: "Resolve",
        portal_title: "CPay: Admin Portal",
        merchant_title: "CPay: Client Portal",
        upload_excel_file: "Import Beneficiaries",
        upload_phones_file: "Import Phones",
        remove_all_rows: "Remove All",
        new_sms: "Compose New SMS",
        send_sms: "Send SMS",
        buy_sms: "Buy SMS",
        buy_now: "Buy SMS Now",
    }/*You an can other languages here*/
});

export default strings;