let styles = {
    titleText: {
        fontWeight: 'bolder',
        fontSize: 14
    },
    dashboardChartPanel: {
        height: 300, 
        width: '45%', 
        margin: "5px", 
        position: 'relative', 
        float:'left'
    },
    moduleToolBarButtons: {
        marginLeft: '5px'
    },
    formDialog: {
        width: 500,
        height: 398
    },
    formDialogLargeWidth: {
        width: 800,
        height: 398
    },
    moreTableContentDialogLargeWidth: {
        width: 1024,
        height: 550
    },
    formDialogContainer: {
        padding: '20px 20px 10px 10px',
        width: '400px'
    },
    formDialogFields: {width: 280, padding: '4px' },
    formDialogFieldsTexField: {width: 280, height:100, padding: '4px' },
    expanderRow: {
        padding: '20px 20px 10px 10px',
    },
    expanderRowHighlight: {
        fontWeight: "bolder",
        margin: "4dp"
    },
    formDialogLargeWidthAddButtons: {
        marginLeft: "0px",
        marginTop: "5px",
        marginRight: "5px",
        marginBottom: "5px"
    },
    numberPresentationGreenBold: {
        color: "#06633c",
        fontWeight: "bolder",
        fontSize: "14px"
    },
    numberPresentationGreen: {
        color: "#06633c",
        fontWeight: "bolder"
    },
    numberPresentationRed: {
        color: "#b32404",
        fontWeight: "bolder"
    },
    commonBlockText: {
        width: 400, 
        margin:"5px", 
        overflow:"scroll", 
        padding:"10px", 
        backgroundColor:"#e6e4e1", 
        border: "1px", 
        borderStartEndRadius:"solid",
        borderColor:"#4d4c4a"
    }
    
};

module.exports = styles;